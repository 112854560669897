<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Answer the following quesions about a
        <number-value :value="concInit" unit="\text{M}" />
        solution of
        <chemical-latex content="NaNO2" />
        at
        <stemble-latex content="$25\,^\circ\text{C.}$" />
      </p>

      <p class="mb-2">
        a) What is the species in the solution that is highest in concentration (other than water)?
        Include the charge (if any) and the phase.
      </p>

      <chemical-notation-input
        v-model="inputs.mostConcentratedSpecies"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Write the balanced chemical equation for the equilibrium reaction for the major species
        that would have an effect on the pH. All species should include the charge (if any) and the
        phase.
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Using the data available in the Appendix of the textbook,
        <a
          href="https://ecampusontario.pressbooks.pub/genchemforgeegees/"
          rel="noopener noreferrer"
          target="_blank"
          >Gen Chem for Gee Gees</a
        >, what is the value for the equilibrium constant for the reaction written in part b)?
      </p>

      <calculation-input
        v-model="inputs.Kb"
        class="mb-5"
        prepend-text="$\text{K:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">What is the concentration of hydroxide ions in this solution?</p>

      <calculation-input
        v-model="inputs.concOH"
        class="mb-5"
        prepend-text="$\ce{[OH-]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">e) What is the pH of the solution?</p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-0"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question466',
  components: {
    NumberValue,
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mostConcentratedSpecies: null,
        balancedEquation: null,
        Kb: null,
        concOH: null,
        pH: null,
      },
    };
  },
  computed: {
    concInit() {
      return this.taskState.getValueBySymbol('concInit').content;
    },
  },
};
</script>
