


































import ChemicalLatex from '../../tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import i18n from '@/locales/composables/i18n';
import {ChemicalEquationValidator} from '@/chemical-equations/utils/ChemicalEquationValidator';
import {computed, defineComponent, PropType, Ref, ref} from '@vue/composition-api';
import debounce from 'lodash.debounce';
import {IReactionFragmentErrors} from '@/chemical-equations/type/IReactionFragmentErrors';

export default defineComponent({
  name: 'ChemicalNotationInput',
  components: {ChemicalLatex, StembleLatex},
  props: {
    showNote: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    inputPlaceholder: {
      type: String as PropType<string>,
      default: () => i18n.t('chemicalNotationInput.placeholder'),
    },
    solo: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    flat: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    outlined: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    prependText: {
      type: String as PropType<string>,
      default: null,
    },
    appendText: {
      type: String as PropType<string>,
      default: null,
    },
    value: {
      type: String as PropType<string>,
      default: null,
    },
    errorDelay: {
      type: Number as PropType<number>,
      default: 650,
    },
    disableValidation: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const reactionErrors: Ref<IReactionFragmentErrors> = ref({});
    const notationValue = computed(() => props.value ?? '');
    const validator = new ChemicalEquationValidator();
    const isReactionValid = computed(
      () => props.disableValidation || validator.isReactionValid(reactionErrors.value)
    );
    const invalidFragments = computed(() => {
      const fragments: string[] = [];
      for (const [fragment, isError] of Object.entries(reactionErrors.value)) {
        if (isError) {
          fragments.push(fragment);
        }
      }
      return fragments;
    });

    function validateReaction() {
      reactionErrors.value = validator.validateReaction(notationValue.value);
    }

    const debouncedValidateReaction = debounce(() => validateReaction(), props.errorDelay);

    function handleInput() {
      reactionErrors.value = {};
      debouncedValidateReaction();
    }

    return {
      notationValue,
      isReactionValid,
      invalidFragments,
      handleInput,
      validateReaction,
    };
  },
});
