import {ChemicalEquationValidationOptions} from '@/chemical-equations/type/ChemicalEquationValidationOptions';
import {IReactionFragmentErrors} from '@/chemical-equations/type/IReactionFragmentErrors';

export class ChemicalEquationValidator {
  static readonly KEY_REACTANTS = 'reactants';
  static readonly KEY_PRODUCTS = 'products';
  static readonly KEY_ARROW = 'arrow';
  static readonly REAGENT_SEPARATOR = '@@@';
  static readonly REGEX_ELEMENT_ANY_SYMBOL = '(?:[A-Za-z]{1,2})';

  static NORMALIZED_ARROWS = {
    '->': '=right=',
    '<=>': '=equilibrium=',
    '<-': '=left=',
  };

  normalizeDashes(str: string): string {
    return str.replace(/[\u{2010}\u{2212}\u{2013}\u{2014}]/gu, '-');
  }

  normalizeReagentSeparators(str: string): string {
    return str.replace(/\s+\+/g, ChemicalEquationValidator.REAGENT_SEPARATOR);
  }

  normalizeArrow(str: string): string {
    let result = str.replace(/\u{27F6}/gu, '->');
    result = result.replace(/\u{27F5}/gu, '<-');
    for (const [arrow, normalized] of Object.entries(ChemicalEquationValidator.NORMALIZED_ARROWS)) {
      result = result.replace(arrow, normalized);
    }
    return result;
  }

  private splitReactantsProducts(str: string) {
    const splits = str.split(/=\w+=/);
    if (splits.length === 1) {
      return {
        [ChemicalEquationValidator.KEY_REACTANTS]: str,
      };
    }
    const regex = /^(.+)?=(\w+)=(.+)?$/;
    const matched = regex.exec(str);
    return {
      [ChemicalEquationValidator.KEY_REACTANTS]: matched?.[1],
      [ChemicalEquationValidator.KEY_ARROW]: matched?.[2],
      [ChemicalEquationValidator.KEY_PRODUCTS]: matched?.[3],
    };
  }

  isReactionValid(
    reaction: string | IReactionFragmentErrors,
    options: ChemicalEquationValidationOptions | null = null
  ): boolean {
    const errors =
      typeof reaction === 'string' ? this.validateReaction(reaction, options) : reaction;
    return Object.values(errors).reduce((acc, fragmentIsError) => {
      return acc && !fragmentIsError;
    }, true);
  }

  validateReaction(
    reactionString: string,
    options: ChemicalEquationValidationOptions | null = null
  ): IReactionFragmentErrors {
    reactionString = this.normalizeDashes(reactionString);
    reactionString = this.normalizeReagentSeparators(reactionString);
    reactionString = this.normalizeArrow(reactionString);

    const splitReaction = this.splitReactantsProducts(reactionString);
    const errors: IReactionFragmentErrors = {};
    for (const [key, value] of Object.entries(splitReaction)) {
      if (
        value &&
        (key === ChemicalEquationValidator.KEY_REACTANTS ||
          key === ChemicalEquationValidator.KEY_PRODUCTS)
      ) {
        const values = value.split(ChemicalEquationValidator.REAGENT_SEPARATOR);
        for (let fragment of values) {
          fragment = fragment.trim();
          errors[fragment] = !this.validateTerm(fragment);
        }
      }
    }
    return errors;
  }

  private makeRecursivePattern(
    start: string,
    end: string,
    patternMethod: 'makeValidationSubscriptPattern' | 'makeValidationSuperscriptPattern',
    nested: boolean = true
  ): string {
    const regexStart = nested ? `^(?:\\d+\\/\\d+|\\d*\\.\\d+|\\d*)\\s*` : '';
    const nestedPattern = nested ? `|\\(` + this[patternMethod](false) + `\\)` : '';
    const regexEnd = nested ? `\\s*(?:\\(\\s*[A-Za-z]+\\s*\\))?$` : '';
    return `${regexStart}${start}${nestedPattern}${end}${regexEnd}`;
  }

  private makeValidationSubscriptPattern(nested: boolean = true): string {
    const start = `(?:(` + ChemicalEquationValidator.REGEX_ELEMENT_ANY_SYMBOL;
    const end = `)(\\_?\\d+|\\_\\{\\s*\\d+\\s*\\})?(?:[\\-\\+]|\\^\\d*[\\-\\+]|\\^\\{\\s*\\d*\\s*[\\-\\+]\\s*\\})?)+`;
    return this.makeRecursivePattern(start, end, 'makeValidationSubscriptPattern', nested);
  }

  private makeValidationSuperscriptPattern(nested: boolean = true): string {
    const start = `(?:(` + ChemicalEquationValidator.REGEX_ELEMENT_ANY_SYMBOL;
    const end = `)(?:[\\-\\+]|\\^\\d*[\\-\\+]|\\^\\{\\s*\\d*\\s*[\\-\\+]\\s*\\})?(?:\\_?\\d+|\\_\\{\\s*\\d+\\s*\\}))+`;
    return this.makeRecursivePattern(start, end, 'makeValidationSuperscriptPattern', nested);
  }

  normalizeChargeSymbols(fragment: string): string {
    return fragment.replace(/[\u2212\u2010\u2013\u2014]/g, '-');
  }

  private validateTerm(fragment: string): boolean {
    fragment = this.normalizeChargeSymbols(fragment);
    fragment = fragment.trim();
    const subscriptPattern = new RegExp(this.makeValidationSubscriptPattern());
    const superscriptPattern = new RegExp(this.makeValidationSuperscriptPattern());
    return subscriptPattern.test(fragment) || superscriptPattern.test(fragment);
  }
}
